@import './theme/core.scss';
.appRoot{
  width: 100%;
  height: 100vh;
  background-color: white;
}
.main{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.background{
  background-color: #DF7467;
  width: 100%;
  height: 100%;
  position:fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  zIndex: 9999,
}
.logo{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.text{
  font-size: 20px;
  color: white;
  font-weight: 600;
  font-family: $noto-sans;
  font-style: normal;
}
