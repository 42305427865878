/**
 * ----------------------------------------
 * Variables
 * ----------------------------------------
 */

$source-san: 'Source Sans Pro';
$noto-sans: 'Noto Serif TC';


/**
 * ----------------------------------------
 * Exports
 * ----------------------------------------
 */

:export {
  noto-sans: $noto-sans;
  source-san: $source-san;
}
